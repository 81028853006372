// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  font-size: 14px;
  color: #7451f8;
  background-color: rgba(117, 81, 248, 0.0941176471);
  cursor: pointer;
  border-radius: 0 0 0 5px;
  transition: background-color 0.3s ease;
}
.editButton:hover {
  background-color: #7451f8;
  color: #fff;
}

.item {
  display: flex;
  gap: 20px;
}

.itemImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.details .itemTitle {
  margin-bottom: 10px;
  color: #555;
  font-size: 18px;
}
.details .detailItem {
  margin-bottom: 10px;
  font-size: 14px;
}
.details .detailItem .itemKey {
  font-weight: bold;
  color: gray;
  margin-right: 5px;
}
.details .detailItem .itemValue {
  font-weight: 300;
}

.derecha,
.bottom {
  box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  padding: 20px;
  margin: 10px 20px;
}
.derecha.derecha,
.bottom.derecha {
  flex: 2 1;
  position: relative;
}

.title {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
}

.iconContainer {
  position: absolute;
  top: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/singleOperador/operador.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,kDAAA;EACA,eAAA;EACA,wBAAA;EACA,sCAAA;AACF;AACE;EACE,yBAAA;EACA,WAAA;AACJ;;AAGA;EACE,aAAA;EACA,SAAA;AAAF;;AAGA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,iBAAA;AAAF;;AAIE;EACE,mBAAA;EACA,WAAA;EACA,eAAA;AADJ;AAIE;EACE,mBAAA;EACA,eAAA;AAFJ;AAII;EACE,iBAAA;EACA,WAAA;EACA,iBAAA;AAFN;AAKI;EACE,gBAAA;AAHN;;AAQA;;EAEE,gDAAA;EACA,aAAA;EACA,iBAAA;AALF;AAOE;;EACE,SAAA;EACA,kBAAA;AAJJ;;AAQA;EACE,eAAA;EACA,WAAA;EACA,mBAAA;AALF;;AAQA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;AALF","sourcesContent":[".editButton {\r\n  position: absolute;\r\n  top: 0;\r\n  right: 0;\r\n  padding: 8px;\r\n  font-size: 14px;\r\n  color: #7451f8;\r\n  background-color: #7551f818;\r\n  cursor: pointer;\r\n  border-radius: 0 0 0 5px;\r\n  transition: background-color 0.3s ease;\r\n\r\n  &:hover {\r\n    background-color: #7451f8;\r\n    color: #fff;\r\n  }\r\n}\r\n\r\n.item {\r\n  display: flex;\r\n  gap: 20px;\r\n}\r\n\r\n.itemImg {\r\n  width: 100px;\r\n  height: 100px;\r\n  border-radius: 50%;\r\n  object-fit: cover;\r\n}\r\n\r\n.details {\r\n  .itemTitle {\r\n    margin-bottom: 10px;\r\n    color: #555;\r\n    font-size: 18px;\r\n  }\r\n\r\n  .detailItem {\r\n    margin-bottom: 10px;\r\n    font-size: 14px;\r\n\r\n    .itemKey {\r\n      font-weight: bold;\r\n      color: gray;\r\n      margin-right: 5px;\r\n    }\r\n\r\n    .itemValue {\r\n      font-weight: 300;\r\n    }\r\n  }\r\n}\r\n\r\n.derecha,\r\n.bottom {\r\n  box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\r\n  padding: 20px;\r\n  margin: 10px 20px;\r\n\r\n  &.derecha {\r\n    flex: 2;\r\n    position: relative;\r\n  }\r\n}\r\n\r\n.title {\r\n  font-size: 20px;\r\n  color: #333;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.iconContainer {\r\n  position: absolute;\r\n  top: 0;\r\n  right: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
