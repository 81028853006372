// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-styling {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.driver-container {
  width: 100%;
  max-width: 600px;
  margin: 50px auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
}

form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
form label {
  margin-bottom: 8px;
  font-size: 14px;
  color: #555;
}
form input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}
form button {
  grid-column: span 2;
  background-color: #3498db;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}
form button:hover {
  background-color: #2980b9;
}
form button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/newdrivers/driver.scss"],"names":[],"mappings":"AACA;EACE,gCAAA;EACA,yBAAA;EACA,SAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,uCAAA;AAAF;;AAEA;EACE,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,qCAAA;EACA,SAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;EACA,WAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,sBAAA;AAAJ;AAGE;EACE,mBAAA;EACA,yBAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,iCAAA;AADJ;AAGI;EACE,yBAAA;AADN;AAII;EACE,yBAAA;EACA,mBAAA;AAFN","sourcesContent":["\n.body-styling {\n  font-family: \"Arial\", sans-serif;\n  background-color: #f4f4f4;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 100vh;\n}\n\n.driver-container {\n  width: 100%;\n  max-width: 600px;\n  margin: 50px auto;\n  background-color: #fff;\n  border-radius: 8px;\n  padding: 20px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\nh1 {\n  text-align: center;\n  color: #333;\n}\n\nform {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 15px;\n\n  label {\n    margin-bottom: 8px;\n    font-size: 14px;\n    color: #555;\n  }\n\n  input {\n    padding: 12px;\n    border: 1px solid #ddd;\n    border-radius: 6px;\n    font-size: 16px;\n    width: 100%;\n    box-sizing: border-box;\n  }\n\n  button {\n    grid-column: span 2;\n    background-color: #3498db;\n    color: #fff;\n    padding: 12px;\n    border: none;\n    border-radius: 6px;\n    cursor: pointer;\n    font-size: 16px;\n    transition: background-color 0.3s;\n\n    &:hover {\n      background-color: #2980b9;\n    }\n\n    &:disabled {\n      background-color: #bdc3c7;\n      cursor: not-allowed;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
