// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  display: flex;
  width: 100%;
}
.list .listContainer {
  flex: 6 1;
}

.separador {
  display: flex;
  justify-content: space-around;
}

.listContainer2 {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/statistics/list.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;AACJ;AAAI;EACE,SAAA;AAEN;;AAEA;EACI,aAAA;EACA,6BAAA;AACJ;;AAEA;EAEI,sDAAA;EACA,aAAA;EACA,YAAA;AACJ","sourcesContent":[".list {\n    display: flex;\n    width: 100%;\n    .listContainer {\n      flex: 6;\n    }\n  }\n\n.separador{\n    display: flex;\n    justify-content: space-around;\n}\n\n.listContainer2 {\n    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n    padding: 20px;\n    margin: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
