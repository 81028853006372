// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  padding: 10px;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 100px;
}
.widget .left,
.widget .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.widget .left .title,
.widget .right .title {
  font-weight: bold;
  font-size: 14px;
  color: rgb(160, 160, 160);
}
.widget .left .counter,
.widget .right .counter {
  font-size: 28px;
  font-weight: 300;
}
.widget .left .link,
.widget .right .link {
  width: max-content;
  font-size: 12px;
  border-bottom: 1px solid gray;
}
.widget .left .percentage,
.widget .right .percentage {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.widget .left .percentage.positive,
.widget .right .percentage.positive {
  color: green;
}
.widget .left .percentage.negative,
.widget .right .percentage.negative {
  color: red;
}
.widget .left .icon,
.widget .right .icon {
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/widget/widget.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,aAAA;EAEA,sDAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;;EAEE,aAAA;EACA,sBAAA;EACA,8BAAA;AACJ;AACI;;EACE,iBAAA;EACA,eAAA;EACA,yBAAA;AAEN;AACI;;EACE,eAAA;EACA,gBAAA;AAEN;AACI;;EACE,kBAAA;EACA,eAAA;EACA,6BAAA;AAEN;AACI;;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAEN;AAAM;;EACE,YAAA;AAGR;AADM;;EACE,UAAA;AAIR;AAAI;;EACE,eAAA;EACA,YAAA;EACA,kBAAA;EACA,oBAAA;AAGN","sourcesContent":[".widget {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  flex: 1;\r\n  padding: 10px;\r\n  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\r\n  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\r\n  border-radius: 10px;\r\n  height: 100px;\r\n\r\n  .left,\r\n  .right {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n\r\n    .title {\r\n      font-weight: bold;\r\n      font-size: 14px;\r\n      color: rgb(160, 160, 160);\r\n    }\r\n\r\n    .counter {\r\n      font-size: 28px;\r\n      font-weight: 300;\r\n    }\r\n\r\n    .link {\r\n      width: max-content;\r\n      font-size: 12px;\r\n      border-bottom: 1px solid gray;\r\n    }\r\n\r\n    .percentage {\r\n      display: flex;\r\n      align-items: center;\r\n      font-size: 14px;\r\n\r\n      &.positive {\r\n        color: green;\r\n      }\r\n      &.negative {\r\n        color: red;\r\n      }\r\n    }\r\n\r\n    .icon {\r\n      font-size: 18px;\r\n      padding: 5px;\r\n      border-radius: 5px;\r\n      align-self: flex-end;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
