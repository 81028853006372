// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  display: flex;
}
.home .homeContainer {
  flex: 6 1;
}
.home .homeContainer .widgets,
.home .homeContainer .charts {
  display: flex;
  padding: 20px;
  gap: 20px;
}
.home .homeContainer .charts {
  padding: 5px 20px;
}
.home .homeContainer .listContainer {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 20px;
}
.home .homeContainer .listContainer .listTitle {
  font-weight: 500;
  color: gray;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,SAAA;AACJ;AACI;;EAEE,aAAA;EACA,aAAA;EACA,SAAA;AACN;AAEI;EACE,iBAAA;AAAN;AAGI;EAEE,sDAAA;EACA,aAAA;EACA,YAAA;AADN;AAGM;EACE,gBAAA;EACA,WAAA;EACA,mBAAA;AADR","sourcesContent":[".home {\r\n  display: flex;\r\n\r\n  .homeContainer {\r\n    flex: 6;\r\n\r\n    .widgets,\r\n    .charts {\r\n      display: flex;\r\n      padding: 20px;\r\n      gap: 20px;\r\n    }\r\n\r\n    .charts {\r\n      padding: 5px 20px;\r\n    }\r\n\r\n    .listContainer {\r\n      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\r\n      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\r\n      padding: 20px;\r\n      margin: 20px;\r\n\r\n      .listTitle {\r\n        font-weight: 500;\r\n        color: gray;\r\n        margin-bottom: 15px;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
