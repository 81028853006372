// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app.dark {
  background-color: #111;
  color: rgb(156, 156, 156);
}
.app.dark .chartGrid {
  stroke: rgba(228, 228, 228, 0.219);
}
.app.dark .table {
  background-color: #111;
}
.app.dark .table .tableCell {
  color: gray;
}
.app.dark .navbar {
  color: #999;
  border-color: #333;
}
.app.dark .navbar .search {
  border-color: gray;
}
.app.dark .sidebar {
  background-color: #111;
  border-color: #333;
}
.app.dark .sidebar .top .logo {
  color: #999;
}
.app.dark .sidebar hr {
  border-color: #333;
}
.app.dark .sidebar ul li:hover {
  background-color: #333;
}
.app.dark .sidebar ul li .icon {
  color: #999;
}
.app.dark .datatable .datagrid {
  color: gray;
  border: none;
}
.app.dark .datatable .datagrid .viewButton,
.app.dark .datatable .datagrid .deleteButton,
.app.dark .datatable .datagrid .cellWithStatus {
  color: gray;
  border: none;
}
.app.dark input {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/style/dark.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,yBAAA;AACF;AACE;EACE,kCAAA;AACJ;AAEE;EACE,sBAAA;AAAJ;AAEI;EACE,WAAA;AAAN;AAIE;EACE,WAAA;EACA,kBAAA;AAFJ;AAII;EACE,kBAAA;AAFN;AAME;EACE,sBAAA;EACA,kBAAA;AAJJ;AAOM;EACE,WAAA;AALR;AAQI;EACE,kBAAA;AANN;AAWQ;EACE,sBAAA;AATV;AAYQ;EACE,WAAA;AAVV;AAiBI;EACE,WAAA;EACA,YAAA;AAfN;AAiBM;;;EAGE,WAAA;EACA,YAAA;AAfR;AAoBE;EACI,6BAAA;AAlBN","sourcesContent":[".app.dark {\r\n  background-color: #111;\r\n  color: rgb(156, 156, 156);\r\n\r\n  .chartGrid {\r\n    stroke: rgba(228, 228, 228, 0.219);\r\n  }\r\n\r\n  .table {\r\n    background-color: #111;\r\n\r\n    .tableCell {\r\n      color: gray;\r\n    }\r\n  }\r\n\r\n  .navbar {\r\n    color: #999;\r\n    border-color: #333;\r\n\r\n    .search {\r\n      border-color: gray;\r\n    }\r\n  }\r\n\r\n  .sidebar {\r\n    background-color: #111;\r\n    border-color: #333;\r\n\r\n    .top {\r\n      .logo {\r\n        color: #999;\r\n      }\r\n    }\r\n    hr {\r\n      border-color: #333;\r\n    }\r\n\r\n    ul {\r\n      li {\r\n        &:hover {\r\n          background-color: #333;\r\n        }\r\n\r\n        .icon {\r\n          color: #999;\r\n        }\r\n      }\r\n    }\r\n  }\r\n\r\n  .datatable {\r\n    .datagrid {\r\n      color: gray;\r\n      border: none;\r\n\r\n      .viewButton,\r\n      .deleteButton,\r\n      .cellWithStatus {\r\n        color: gray;\r\n        border: none;\r\n      }\r\n    }\r\n  }\r\n\r\n  input{\r\n      background-color: transparent;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
