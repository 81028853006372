// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  display: flex;
  width: 100%;
}
.list .listContainer {
  flex: 6 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/listViajes/list.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;AACJ;AAAI;EACE,SAAA;AAEN","sourcesContent":[".list {\n    display: flex;\n    width: 100%;\n    .listContainer {\n      flex: 6;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
