// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table .cellWrapper {
  display: flex;
  align-items: center;
}
.table .cellWrapper .image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}
.table .status {
  padding: 5px;
  border-radius: 5px;
}
.table .status.Approved {
  color: green;
  background-color: rgba(0, 128, 0, 0.151);
}
.table .status.Pending {
  color: goldenrod;
  background-color: rgba(189, 189, 3, 0.103);
}`, "",{"version":3,"sources":["webpack://./src/components/table/table.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;AAAN;AAIE;EACE,YAAA;EACA,kBAAA;AAFJ;AAII;EACE,YAAA;EACA,wCAAA;AAFN;AAII;EACE,gBAAA;EACA,0CAAA;AAFN","sourcesContent":[".table {\r\n  .cellWrapper {\r\n    display: flex;\r\n    align-items: center;\r\n\r\n    .image {\r\n      width: 32px;\r\n      height: 32px;\r\n      border-radius: 50%;\r\n      margin-right: 10px;\r\n      object-fit: cover;\r\n    }\r\n  }\r\n\r\n  .status {\r\n    padding: 5px;\r\n    border-radius: 5px;\r\n\r\n    &.Approved {\r\n      color: green;\r\n      background-color: rgba(0, 128, 0, 0.151);\r\n    }\r\n    &.Pending {\r\n      color: goldenrod;\r\n      background-color: rgba(189, 189, 3, 0.103);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
