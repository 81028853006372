// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login .logo-container {
  margin-bottom: 30px;
}
.login .logo-image {
  width: 200px;
  height: auto;
}
.login p {
  /* Estilos para la etiqueta <p> */
  font-size: 16px; /* Tamaño de fuente */
  color: #333; /* Color de texto */
  margin-bottom: 20px; /* Margen inferior para separar el texto del formulario */
  font-weight: bold;
}
.login form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login form input {
  width: 300px;
  height: 40px;
  margin: 20px;
}
.login form button {
  width: 300px;
  height: 40px;
  border: none;
  background-color: purple;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.login form span {
  font-size: 12px;
  color: red;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/login.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAEF;AAAE;EACI,mBAAA;AAEN;AACE;EACI,YAAA;EACA,YAAA;AACN;AAEE;EACI,iCAAA;EACA,eAAA,EAAA,qBAAA;EACA,WAAA,EAAA,mBAAA;EACA,mBAAA,EAAA,yDAAA;EACA,iBAAA;AAAN;AAGE;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AADN;AAGM;EACI,YAAA;EACA,YAAA;EACA,YAAA;AADV;AAKM;EACI,YAAA;EACA,YAAA;EACA,YAAA;EACA,wBAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AAHV;AAMM;EACI,eAAA;EACA,UAAA;EACA,gBAAA;AAJV","sourcesContent":[".login {\r\n  height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n\r\n  .logo-container {\r\n      margin-bottom: 30px;\r\n  }\r\n\r\n  .logo-image {\r\n      width: 200px;\r\n      height: auto;\r\n  }\r\n\r\n  p {\r\n      /* Estilos para la etiqueta <p> */\r\n      font-size: 16px; /* Tamaño de fuente */\r\n      color: #333; /* Color de texto */\r\n      margin-bottom: 20px; /* Margen inferior para separar el texto del formulario */\r\n      font-weight: bold;\r\n  }\r\n\r\n  form {\r\n      display: flex;\r\n      flex-direction: column;\r\n      align-items: center;\r\n\r\n      input {\r\n          width: 300px;\r\n          height: 40px;\r\n          margin: 20px;\r\n          \r\n      }\r\n\r\n      button {\r\n          width: 300px;\r\n          height: 40px;\r\n          border: none;\r\n          background-color: purple;\r\n          color: white;\r\n          font-weight: bold;\r\n          cursor: pointer;\r\n      }\r\n\r\n      span {\r\n          font-size: 12px;\r\n          color: red;\r\n          margin-top: 10px;\r\n      }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
