// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcomeMessage {
  background-color: #f4f4f4;
  padding: 250px;
  border-radius: 8px;
  margin: 90px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.welcomeMessage h2 {
  color: #333;
}

.welcomeMessage p {
  color: #555;
}`, "",{"version":3,"sources":["webpack://./src/components/homeContainer/homeContain.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;EACA,uCAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".welcomeMessage {\n  background-color: #f4f4f4;\n  padding: 250px;\n  border-radius: 8px;\n  margin: 90px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.welcomeMessage h2 {\n  color: #333;\n}\n\n.welcomeMessage p {\n  color: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
